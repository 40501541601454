import React from 'react'
import styled from 'styled-components';
import SliderComp from './Slider';
import { Zoom } from 'react-awesome-reveal';

const Projects = () => {
  return (
    <Container id='project'>
        <Zoom>
            <h1>Recent <span className="green">Projects</span></h1>
            <p>Welcome to my portfolio of recent web development projects! As a seasoned web developer, I am excited to showcase the culmination of my skills and experience in this dynamic field. Each project represents a unique blend of innovation, creativity, and technical proficiency, designed to exceed client expectations and elevate the user experience. From sleek and responsive designs to robust backend architectures, my projects demonstrate a commitment to delivering cutting-edge solutions tailored to diverse industries and requirements. Join me on this journey as we delve into a collection of web experiences that combine aesthetics, functionality, and seamless performance. Get ready to explore a world where imagination meets technology and discover the limitless possibilities of web development.</p>
        </Zoom>
        <Slide>
            <SliderComp/>
        </Slide>
    </Container>
  )
}

export default Projects;

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 3rem 0;
    text-align: center;
    position: relative;
    @media(max-width: 840px){
        width: 90%;
    }
    h1{
        font-size: 1.9rem;
    }

    p{
        width: 28rem;
        margin: 0 auto;
        padding: 1rem 0;
        font-size: 0.9rem;
        @media(max-width : 500px){
            width: 90%;
        }
    }
    
`

const Slide = styled.div``