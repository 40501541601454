import React, { useRef } from 'react'
import Slider from 'react-slick';
import Project from './Project';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';

let data = [
    {
        img : "https://res.cloudinary.com/ghazni/image/upload/v1661324124/Yt-portfolio/ui5_mnvdaw.png",
        disc : "Introducing GameQuest: Your Ultimate Gaming Companion! Are you a passionate gamer always on the lookout for new challenges and exciting adventures? Look no further! GameQuest is here to revolutionize your gaming experience. With a sleek and intuitive interface, GameQuest is the must-have app for every gaming enthusiast."
    },
    {
        img : "https://res.cloudinary.com/ghazni/image/upload/v1661324074/Yt-portfolio/ui4_jku3ol.png",
        disc : "Experience the Wonder of the Moon with GOmoon: Your Celestial Guide! Embark on a captivating journey through space and explore the mysteries of Earth's nearest neighbor with LunaScape, the ultimate moon app. Whether you're an astronomy enthusiast, a stargazer, or simply curious about the celestial world, LunaScape offers an immersive and educational experience like no other."
    },
    {
        img : "https://res.cloudinary.com/ghazni/image/upload/v1661323981/Yt-portfolio/ui2_wtollo.png",
        disc : "Engage in Epic Warfare with Battlefront: A Thrilling War Gaming Experience!Prepare for an adrenaline-pumping journey into the heart of intense battles and strategic warfare with Battlefront, the ultimate war game app. Immerse yourself in the chaos of war, lead armies, and conquer your enemies on the virtual battlefield. With stunning visuals, immersive gameplay, and a vast array of strategic options, Battlefront will test your skills and push you to the limits."
    }, 
    {
        img : "https://res.cloudinary.com/ghazni/image/upload/v1661323980/Yt-portfolio/ui3_akynn4.png",
        disc : "Welcome to BlogZen: Unleash Your Creativity and Share Your Voice! BlogZen is the ultimate platform for bloggers of all backgrounds to express themselves, share their ideas, and connect with a global community. Whether you're a seasoned writer, a passionate storyteller, or an aspiring influencer, BlogZen provides the perfect canvas to publish compelling content and engage with like-minded individuals."
    },
    {
        img : "https://res.cloudinary.com/ghazni/image/upload/v1661323979/Yt-portfolio/ui1_n3uiaz.png",
        disc : "Introducing My Wallet the ultimate personal finance app designed to revolutionize the way you track and manage your spending. With [App Name], taking control of your finances has never been easier. Say goodbye to the hassle of manual expense tracking and complicated spreadsheets. Our intuitive and user-friendly interface makes it effortless to record and categorize your expenses on the go. Simply input your transactions, set budgets, and let [App Name] handle the rest. It's like having a personal finance assistant right at your fingertips."
    }
];

var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode : false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode : false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode : false
        }
      }
    ]
  };
const SliderComp = () => {
  const arrowRef = useRef(null);
    let sliderProject = "";
    sliderProject = data.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
      {sliderProject}
      </Slider>
      <Buttons>
        <button 
        onClick={() => arrowRef.current.slickPrev()}
        className='back'><IoIosArrowBack/></button>
        <button 
        onClick={() => arrowRef.current.slickNext()}
        className='next'><IoIosArrowForward/></button>
      </Buttons>
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.100);
    cursor: pointer;
    color: #01be96;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back{
    left: -1rem;
  }
`