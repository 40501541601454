import React from "react";
import { MdDesignServices } from "react-icons/md";
import { FiCodesandbox } from "react-icons/fi";
import { CgWebsite } from "react-icons/cg";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h4>
          My <span className="green">services</span>
        </h4>
        <h1>What I Do</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={MdDesignServices}
            title={"mobile developer"}
            disc={`As a highly skilled and experienced mobile developer, I offer comprehensive and customized mobile app 
                development services to create innovative and user-friendly mobile applications for various platforms, including iOS, Android, and hybrid apps.`}
          />
        </Slide>
        <Slide direction="up">
          <Card
            Icon={FiCodesandbox}
            title={"web developer"}
            disc={`As a skilled and experienced web developer, I provide comprehensive web development services to create 
                visually appealing, interactive, and functional websites that cater to the unique needs of businesses and individuals.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={CgWebsite}
            title={"data scientist"}
            disc={`As a skilled and experienced data scientist, I provide comprehensive data science services to help 
                businesses and organizations derive actionable insights from their data, make informed decisions, and achieve their data-driven goals.`}
          />
        </Slide>
      </Cards>
    </Container>
  );
};

export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;
const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
