import React, { useRef } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import ClientSlider from './ClientSlider';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Slide } from 'react-awesome-reveal';

let clients = [
    {
        name : "John Michel",
        position : "Restaruant Owner",
        img_url : "https://t4.ftcdn.net/jpg/02/90/27/39/360_F_290273933_ukYZjDv8nqgpOBcBUo5CQyFcxAzYlZRW.jpg",
        stars : 3,
        disc : `Working with Ambrose has been a game changer for our business! Their expertise 
        in web development has transformed our website into a modern, user-friendly, and visually appealing 
        platform. They were responsive, collaborative, and delivered results beyond our expectations. We've 
        seen an increase in website traffic, engagement, and conversions since the website revamp. We highly 
        recommend Ambrose for anyone looking for top-notch web development services!`
    },
    {
        name : "Simeon Olgunjulugbe",
        position : "Property Manager",
        img_url : require('../../img/IMG_0300.JPG'),
        stars : 4,
        disc : `I am extremely impressed with the web development services provided by Ambrose. 
        They are not only technically skilled, but also highly creative in designing websites that are visually 
        appealing and user-friendly. They paid attention to every detail and ensured that the website reflected 
        my brand identity. The website was delivered on time and within budget, and Ambrose 
        provided prompt and professional communication throughout the process. I highly recommend Ambrose
        to anyone looking for a top-notch web developer.`
    },
    {
        name : "Brittney Debrum",
        position : "Interior Designer",
        img_url : require('../../img/christina-wocintechchat-com-lFntEHwQvi4-unsplash.jpg'),
        stars : 5,
        disc : `We are thrilled with the website created by Ambrose! They understood our vision 
        and translated it into a stunning website that perfectly represents our brand. The website is fast, 
        responsive, and optimized for SEO, resulting in improved search rankings and higher organic traffic. 
        Ambrose was a pleasure to work with, providing timely communication and exceptional 
        technical expertise. We are extremely satisfied with the final product and would highly recommend 
        Ambrose for anyone seeking a reliable and skilled web developer.`
    },
    {
        name : "Aaron Brown",
        position : "CEO",
        img_url : require('../../img/linkedin-sales-solutions-pAtA8xe_iVM-unsplash.jpg'),
        stars : 5,
        disc : `I had a fantastic experience working with Ambrose on my website project. They 
        were highly skilled, responsive, and collaborative, and brought my vision to life with their expertise
        in web development. The website they created is visually stunning, highly functional, and optimized for 
        performance. Ambrose provided exceptional customer service, promptly addressing any questions 
        or concerns I had. I am thrilled with the final result and would highly recommend Ambrose 
        for any web development needs.`
    },
]
var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 530,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]}

const Clients = () => {
    const arrowRef = useRef(null);
    let clientDisc = "";
    clientDisc = clients.map((item, i) => (
        <ClientSlider item={item} key={i}/>
    ))
  return (
    <Container id='client'>
        <Slide direction="left">
            <span className="green">testimonials</span>
            <h1>what clients say</h1>
        </Slide>
        <Testimonials>
            <Slider ref={arrowRef} {...settings}>
                {clientDisc}
            </Slider>
            <Buttons>
                <button
                onClick={() => arrowRef.current.slickPrev()}
                ><IoIosArrowBack/></button>
                <button
                onClick={() => arrowRef.current.slickNext()}
                ><IoIosArrowForward/></button>
            </Buttons>
        </Testimonials>
    </Container>
  )
}

export default Clients

const Container = styled.div`
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0;

    @media(max-width:840px){
        width: 90%;
    }

    span{
        font-weight: 700;
        text-transform: uppercase;
    }

    h1{
        padding-top: 1rem;
        text-transform: capitalize;
    }

    .slick-list, .slick-slider, .slick-track{
        padding: 0;
    }

    .slick-dots{
        text-align: left;
        margin-left: 1rem;
    }

    .slick-dots li button:before{
        content: "";
    }

    .slick-dots li button{
        width: 9px;
        height: 4px;
        background: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(43, 43, 53) 100%);
        padding: 0.1rem;
        margin-top: 1rem;
        transition: all 400ms ease-in-out;
        border-radius: 50px;
    }
    
    .slick-dots li.slick-active button{
        background: #01be96;
        width: 15px;
    }

    .slick-dots li{
        margin: 0;
    }
`

const Testimonials = styled.div`
    margin-top: 2rem;
    position: relative;
`
const Buttons = styled.div`
    position: absolute;
    right: 0.7rem;
    bottom: -2rem;

    button{
        background-color: transparent;
        margin-left: 0.5rem;
        border: none;
        color: #01be96;
        cursor: pointer;
        font-size: 1.1rem;
    }

    @media(max-width:530px){
        display: none;
    }
`